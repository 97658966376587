
import { defineComponent, onMounted, ref, watch } from 'vue';
import NFTCard from '@/components/gem-bank/NFTCard.vue';
import { async } from '@firebase/util';
import { showRefreshButton } from '@/config'

import {
	getFirestore,
	collection,
	getDocs,
	setDoc,
	doc,
	updateDoc,
	getDoc,
	deleteDoc,
	query,
    addDoc
} from "firebase/firestore";
import DisplayRefresh from '../gem-farm/DisplayRefresh.vue';

export default defineComponent({
  components: { NFTCard, DisplayRefresh },
  emits: ['selected', 'unstake', 'stake', 'claim', 'refresh-farmer'],
  props: {
    title: String,
    nfts: Array,
    isStaked: String,
    loading: Boolean,
    available: String,
    farm: String,
    farmAcc: Object,
    farmer: String,
    farmerAcc: Object,
  },
  watch: {
    nfts: function test(oldVal, newVal) {
      this.selectedVaultNFTs = 0
      this.selectedWalletNFTs = 0
    }
  },
  setup(props, ctx) {
    const selectedWalletNFTs = ref<Number>(0)
    const selectedVaultNFTs = ref<Number>(0)

    const handleSelected = (e: any) => {
      ctx.emit('selected', e);
      console.log(e)
      if (e.location === 'wallet') {
        if (e.selected === false) {
          selectedWalletNFTs.value = +selectedWalletNFTs.value - 1
        } else {
          selectedWalletNFTs.value = +selectedWalletNFTs.value + 1
        }
      } else {
          if (e.selected === false) {
            selectedVaultNFTs.value = +selectedVaultNFTs.value - 1
          } else {
            selectedVaultNFTs.value = +selectedVaultNFTs.value + 1
          }
      }
    };
    console.log(props.isStaked)
    selectedVaultNFTs.value = 0
    selectedWalletNFTs.value = 0

    const calcLength = () => {
      let i=0
      if (props) {
        if (props.nfts) {
          props.nfts.forEach((nft: any) => {
            if (nft?.externalMetadata.symbol !== 'MMT') {
              i++
            }
          })
        }
      }
      return i
    }

    console.log("Amount to claim (manual calculation -- NOT using the refresh button): " + +((((Date.now() - props.farmerAcc?.rewardA.fixedRate.lastUpdatedTs*1000)/1000)*(props.farmerAcc?.rewardA.fixedRate.promisedSchedule.baseRate/1000000000))* (+props.farmerAcc?.gemsStaked)).toFixed(4))
    //console.log("ACTUAL amount to claim according to the refresh button -- this value is straight from the contract, 100% correct, in LAMPORTS: " + props?.farmerAcc?.value.rewardA.accruedReward)

    const refreshXP = ref<boolean>(false)
    
    const unstakeAll = async() => {
      ctx.emit('unstake', 'unstake')
    }

    return {
      handleSelected,
      calcLength,
      selectedWalletNFTs,
      selectedVaultNFTs,
      refreshXP,
      unstakeAll,
      showRefreshButton
    };
  },
});
