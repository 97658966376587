
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
  },
  props: {
    message: String,
    submessage: String,
    no: Function

  },
  setup(props) {



    return {

    };
  },
});
